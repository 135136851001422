import { createI18n } from 'vue-i18n'
import router from '../router'

let languages = ['en', 'nl', 'fr']
const i18n = createI18n()

console.log("i18n init", router)

let loadedLanguages = [] // our default language that is preloaded
router.beforeEach((to, from, next) => {
  let lang = to.params.lang;
  let lang_changed = false;
  if (!lang) { lang = navigator.language.split('-')[0]; lang_changed = true; }
  if (!languages.includes(lang)) { lang = languages[0]; lang_changed = true; }
  if (lang_changed) {    
    let translatedPath = '/' + lang + (to.path.charAt(0) != '/' ? '/' : '') + to.path;
    return next({ path: translatedPath, query: to.query, hash: to.hash });
  }
  if (loadedLanguages.includes(lang)) return next();
  import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(
    messages => {
      document.querySelector('html').setAttribute('lang', lang)
      loadedLanguages.push(lang)
      console.log("setting messages for lang", lang, messages.default)
      i18n.global.setLocaleMessage(lang, messages.default);
      i18n.global.locale = lang;
      next()
    }
  )
})

export default i18n