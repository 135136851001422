import { createWebHistory, createRouter } from "vue-router";

const lang = "/:lang([a-z][a-z])?/"

const routes = [
  {
    path: `${lang}/`,
    name: "Home",
    //component: Homepage, 
    component: () => import(/* webpackChunkName: "homepage" */ '@/components/Home.vue'), 
  },
  {
    path: `${lang}/about`,
    name: "About",
    component: () => import(/* webpackChunkName: "aboutpage" */ '@/components/About.vue'), 
  },
  {
    path: `${lang}/test`,
    name: "Test",
    component: () => import(/* webpackChunkName: "testpage" */ '@/components/Test.vue'), 
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
